// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Variables LK
.cleaner_h0 {clear: both;  width: 100%;  height: 0px; }
.cleaner_h1 {clear: both;  width: 100%;  height: 10px;}
.cleaner_h2 {clear: both;  width: 100%;  height: 20px;}
.cleaner_h3 {clear: both;  width: 100%;  height: 30px;}
.cleaner_h4 {clear: both;  width: 100%;  height: 40px;}
.cleaner_h5 {clear: both;  width: 100%;  height: 50px;}
.cleaner_h6 {clear: both;  width: 100%;  height: 60px;}
.row-eq-h {
    display: table;
    width:100%;
    .col-eq-h {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}
.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
/* Menu */
#header-menu {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c0c1c3+0,d1d0d1+100 */
    background: #ffffff; /* Old browsers */
    -webkit-box-shadow: 0px 23px 30px -19px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 23px 30px -19px rgba(0,0,0,0.75);
    box-shadow: 0px 23px 30px -19px rgba(0,0,0,0.75);
    position:relative;
    z-index:1;
    .menu-principal {
        display:inline-block;
        float:right;
        margin-top:38px;
        li a {
            //border-right:1px solid #231F20;
            //color:#3C4F6D;
            color:#068B31;
            font-weight:bold;
            line-height:36px;
            //margin-top:23px;
            padding-top:0px;
            padding-bottom:0px;
            border-right: 1px dotted #3C4F6D;
            &.not-b {
                border-right:0px;
            }
            &:hover {
                background: #3C4F6D;
                color: #fff;
                border-radius: 5px;
            }
            @media (max-width: @screen-sm-max) {
                & {
                    padding-left:10px;
                    padding-right:10px;
                }
            }
        }
        li.active a {
            background: #3C4F6D;
            color: #fff;
            border-radius: 5px;
        }
    }
    .menu-social {
        border-left:1px solid #231F20;
        font-size:15px;
        padding-left:15px;
        li a {
            color: #231F20;
            &:hover {
                background:#eeeeee;
                color:#8E288A;
            }
        }
    }
    #navbar-collapse-1 {
        text-align:center
    }
    .navbar-brand {
        padding:15px;
        img {
            //margin-top:10px;
            width:100%;
        }
    }
    #menu-action {
        margin: 15px 0px 10px;
        float:right;
        .btn-act-1 {
            color:#fff
        }
        .menu-tel {
            color:#3C4F6D;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
            .fa {
                color:#e85c02;
            }
        }
    }
    @media (max-width: 1200px) {
        .menu-social {
            padding-left:5px;
            li a {
                padding-left:10px;
                padding-right:10px;
            }
        }
    }
    @media (max-width: 1030px) {
        .menu-principal { 
            li a {
                padding-left:10px;
                padding-right:10px;
                font-size:13px;
            }
        }
        .menu-social {
            padding-left:5px;
            li a {
                padding-left:5px;
                padding-right:5px;
            }
        }        
    }
    @media (min-width: 768px) and (max-width: 883px) {
        .menu-principal { 
            li a {
                line-height:30px;
                margin-top:35px;
                padding-left:8px;
                padding-right:8px;
            }
        }
        .menu-social {
            border:0px;
            position: absolute;
            right: 20px;
            top: 0;
            li a {
                padding:10px;
            }
        }        
    }
}
#top-line {
    height:30px;
    background:#eee;
}
/* Menu */
.carousel .carousel-control .fa {
    font-size:50px;
    color:#fff;
    position:relative;
    top:45%;
}
.line-carousel {
    background-color:rgba(0, 0, 0, .5);
    height:40px;
    margin-top:-40px;
    position:relative;
    @media (max-width: @screen-sm-max) { 
        & {
            height:30px;
            margin-top:-30px;
        }
    }
    @media (max-width: @screen-xs-max) { 
        & {
            height:20px;
            margin-top:-20px;
        }
    }
}
.sec-top {
    background:url(../img/fnd_top.jpg) repeat-x bottom;
}
/* Productos */
#categorias {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6b6e70+0,c0c1c2+15,eeebea+100 */
    background: #6b6e70; /* Old browsers */
    background: -moz-linear-gradient(left,  #6b6e70 0%, #c0c1c2 15%, #eeebea 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #6b6e70 0%,#c0c1c2 15%,#eeebea 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #6b6e70 0%,#c0c1c2 15%,#eeebea 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b6e70', endColorstr='#eeebea',GradientType=1 ); /* IE6-9 */
    margin-bottom:30px;
    padding:20px 15px;
    .row {
        background:url(../img/logo_upa_productos.png) no-repeat center right;
    }
    .categoria_list {
        .cat_tit {
            display:inline-block;
            font-size: 32px;
            font-weight: bold;
            margin:0px;
            max-width:180px;
            padding:0px;
            vertical-align:top;
            text-align:right;
            &.tit-c-1 {
                color:#1177B0;
            }
            &.tit-c-2 {
                color:#C091B5;
            }
        }
        .cat_list {
            border-left: 1px solid #000;
            display:inline-block;
            margin:0px;
            margin-left:12px;
            padding:0px;
            padding-left: 28px;
            text-align:left;
            li {
                 color:#000;
                list-style-image:url(../img/cat_list.png);
                a {
                    color:#000;
                }
            }
            &.list-2 {
                border-left: 1px solid #9C9D9F;
                li {
                    color:#777;
                    list-style-image:url(../img/cat_list_2.png);
                    margin:7px 0px;
                    a {
                        color:#777;
                    }
                }
            }
        }
    }
    .s-left {
        text-align:right;
    }
    @media (max-width: @screen-xs-max) { 
        & {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b5bcbc+0,c0c1c2+22,eeebea+100 */
            background: #b5bcbc; /* Old browsers */
            background: -moz-linear-gradient(left,  #b5bcbc 0%, #c0c1c2 22%, #eeebea 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #b5bcbc 0%,#c0c1c2 22%,#eeebea 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #b5bcbc 0%,#c0c1c2 22%,#eeebea 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5bcbc', endColorstr='#eeebea',GradientType=1 ); /* IE6-9 */
            padding:10px 15px;
        }
        .row {
            background:none;
        }
        .s-left {
            text-align:left;
        }
        .categoria_list {
            margin-top:0px;
            margin-bottom:10px;
            .cat_tit {
                font-size:20px;
                max-width:100%;
                margin-top:10px;
            }    
        }
    }
}
.pro-item {
    border: 1px solid #D9E8F4;
    display:inline-block;
    height:150px;
    //margin:0px 10px;
    overflow:hidden;
    padding:10px;
    position:relative;
    width:150px;
    white-space:nowrap;
    img {
       margin-left: -23%;
       width: 145%;
       vertical-align: middle;
    }
    .pro-mask {
       position: absolute;
       overflow: hidden;
       width:100%;
       height:80%;
       top: 0;
       left: 0;
       opacity: 0;
       background:rgba(0,100,156,0.0);
       box-sizing:border-box;
       transition: all 0.4s ease-in-out;
       text-align:center;
       h2 {
           font-size: 20px;
            color: white;
            white-space: initial;
       }
       .link {
            display: block;
            padding: 5px 20px;
            border: 1px solid #fff;
            color: #fff;
            position: absolute;
            bottom: 10px;
            left: 23%;  
        }
    }
    &:hover .pro-mask {
       overflow:visible;
       opacity: 1;
       //background:rgba(0,100,156,0.8);
       background:rgba(0,0,0,0.65);
       width:100%;
       height:100%;
       border-radius:2px;
    }

    @media (min-width: @screen-sm-max) { 
         width:23%;
    }
    @media (min-width: @screen-md-max) { 
        width:18%;
        img {
            margin-left: -15%;
            width: 125%;
        }
    }
    @media (max-width: @screen-sm-max) { 
        & .pro-mask {
            .link {
                left: 20%;
                padding: 5px 10px;
            }
        }
    }
}
.view {
   margin: 10px;
   float: left;
   border: 5px solid #fff;
   overflow: hidden;
   position: relative;
   text-align: center;
   box-shadow: 0px 0px 5px #aaa;
   cursor: default;
}

.view .mask, .view .content {
   width: 300px;
   height: 200px;
   position: absolute;
   overflow: hidden;
   top: 0;
   left: 0;
}

.view img {
   display: block;
   position: relative;
}

.view a.info {
   background:url(../img/link.png) center no-repeat;
   display: inline-block;
   text-decoration: none;
   padding:0;
   text-indent:-9999px;
   width:20px;
   height:20px;
}
    .third-effect .mask {
   opacity: 0;
   overflow:visible;
   border:100px solid rgba(0,0,0,0.7);
   box-sizing:border-box;
   transition: all 0.4s ease-in-out;
}

.third-effect a.info {
   position:relative;
   top:-10px; /* Center the link */
   opacity: 0;
   transition: opacity 0.5s 0s ease-in-out;
}

.third-effect:hover .mask {
   opacity: 1;
   border:100px solid rgba(0,0,0,0.7);
}

.third-effect:hover a.info {
   opacity:1;
   transition-delay: 0.3s;
}
/* Producto */
#page-producto {
    padding-bottom:40px;
    .line-separator{
        // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ededee+0,bcbdbe+50,ededee+100 */
        background: #ededee; /* Old browsers */
        background: -moz-linear-gradient(left,  #ededee 0%, #bcbdbe 50%, #ededee 100%); // FF3.6-15 */
        background: -webkit-linear-gradient(left,  #ededee 0%,#bcbdbe 50%,#ededee 100%); // Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #ededee 0%,#bcbdbe 50%,#ededee 100%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededee', endColorstr='#ededee',GradientType=1 ); // IE6-9 */
        clear: both;  
        width: 100%;  
        height: 1px; 
        margin: 25px 0px;
    }
    .pro-header {
        padding-top:30px;
        h3 {
            margin:0px;
        }
    }
    h1 {
        color:#6D6E71;
        font-size:50px;
        font-weight:bold;
        margin:0px;
        text-transform:uppercase;
        //margin-bottom:10px;
    }
    h2,h3 {
        color:#939597;
        font-style:italic;
        font-weight:bold;
        margin:0px;
        //margin-bottom:10px;
    }
    h2 {
        font-size:25px;
    }
    h3 {
        font-size:20px;
        margin-bottom:30px
    }
    p {
        font-size:20px;
        color:#939597;
    }
    .pro-adj {
        display:inline-block;
        margin-right:20px;
        text-align:center;
        span {
            color:#00A0DC;
            display:block;
            font-size:20px;
            text-decoration:underline;
        }
        img {
                width: 100%;
        }
        .img-data {
            position:relative;
            .adj-count {
                color:#fff;
                font-size:35px;
                font-weight:bold;
                padding-left:5px;
                position:absolute;
                text-shadow: 3px 3px 4px rgba(48, 51, 48, 1);
            }
            .adj-epi {
                background-color: rgba(233, 235, 239, 0.6);
                background: rgba(233, 235, 239, 0.6);
                color:#181818;
                font-size: 18px;
                line-height: 17px;
                padding-right:5px;
                padding-bottom:5px;
                padding-top:5px;
                position:absolute;
                bottom:0px;
                text-align:right;
                width:100%;
            }
        }
        &:hover {
            opacity:0.9;
        }
    }
    .pro-item {
        border: 1px solid #D9E8F4;
        display:inline-block;
        height:150px;
        //margin:0px 10px;
        overflow:hidden;
        padding:10px;
        position:relative;
        width:150px;
        white-space:nowrap;
        img {
           margin-left: -23%;
           width: 145%;
           vertical-align: middle;
        }
    }
    @media (min-width: @screen-sm-max) { 
        .row {
            margin-left:100px;
            margin-right:100px;
        }
    }
    @media (max-width: @screen-xs-max) { 
        .line-separator{
            margin:15px 0px;
        }
        h1 {
            font-size:30px;
        }
        h2 {
            font-size:18px;
        }
        h3 {
            font-size:16px;
            margin-bottom:15px
        }
        p {
            font-size:16px;
            color:#939597;
        }
        .pro-adj {
            max-width:100px;
            span {
                font-size:16px;
            }
        }
    }
}
@media (min-width: @screen-sm-max) { 
    #productos-similares .row {
        margin-left:100px;
        margin-right:100px;
    }
}
/* Estructuras */
.pagina-header {
    position:relative;
    .pagina-tit {
        position: absolute;
        //width: 100%;
        //height: 100%;
        text-align: center;
        vertical-align: middle;
        top: 35%;
        h1 {
            color: #ffffff;
            font-family: "Roboto", Helvetica, Arial, sans-serif;
            font-size: 30px;
            font-weight: bold;
            font-style: italic;
            margin:0px;
            text-shadow: 3px 3px 10px rgba(20, 20, 20, 0.8);
        }
        .page-subtit {
            color: #ffffff;
            font-family: "Roboto", Helvetica, Arial, sans-serif;
            font-size:30px;
            font-style: italic;
            text-shadow: 3px 3px 5px rgba(20, 20, 20, 0.8);
        }
    }
    &.contacto .pagina-tit {
        top: 20%;
        h1 {
            font-style:normal;
        }
        .page-subtit {
            font-weight:lighter;
            line-height:42px;
            @media (max-width: @screen-sm-max) { 
                line-height:22px;
            }
        }
    }
    .img-responsive {
        width:100%;
        max-width:auto;
    }
    .img-tit-xs {
        display:none
    }
    @media (max-width: @screen-sm-max) { 
        .pagina-tit {
            h1 {
                font-size: 45px;
                text-shadow: 3px 3px 2px rgba(20, 20, 20, 0.5);
            }
            .page-subtit { 
                font-size:25px;
                text-shadow: 2px 2px 3px rgba(20, 20, 20, 0.6);
            }
        }
    }
    @media (max-width: @screen-xs-max) { 
        .pagina-tit {
            h1 {
                font-size: 22px;
                text-shadow: 3px 3px 2px rgba(20, 20, 20, 0.5);
            }
            .page-subtit { 
                font-size:16px;
                text-shadow: 2px 2px 3px rgba(20, 20, 20, 0.6);
            }
        }
        &.contacto {
            .pagina-tit {
                top:15px;
                h1 {
                    font-size: 20px;
                }
                .page-subtit { 
                    font-size:13px;
                    line-height:10px;
                }
            }
        }
        .img-tit-xs {
            display:block;
        }
        .img-tit-sm {
            display:none
        }
    }
}
/* Fondos */
.pagina-fnd-1 {
    background: url(../img/page_fnd_1.png) no-repeat top center;
    background-size:100% 100%;
    @media (max-width: @screen-xs-max) {
        & {
             background-size:100%;
             background-repeat: repeat-y
        }
    }
}
.pagina-fnd-2 {
    color:#fff;
    .tit {
        color: #fff;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        background: #C66300;
        padding: 3px 6px;
        border-radius: 7px;
    }
}
.pagina-fnd-3 {
    background: url(../img/intro_contact.jpg) no-repeat center center;
}
.btn-action {
    margin: 0 auto;
    background: none;
    border: 2px solid;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
    -webkit-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.75);
}
#intro-1, #intro-2 {
    .left {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        padding: 60px;
        p {
            font-size: 20px;
            margin-bottom:20px;
        }
        ul  {
            font-size:17px;
            line-height:30px;
        }
    }
    .right {
        padding:0px;
        img {
            width:100%;
        }
    }
}
#intro-1 {
    background: url(../img/fnd_wood.jpg) repeat;
}
#intro-2 {
    background: url(../img/fnd_wood2.jpg) repeat;
}
ul.check li {
  list-style-image: url(../img/check.png);
}
/* Nuestra empresa */
.text-container {
    max-width:485px;
    margin:0 auto;
}
#nuestra-empresa, .box-1, .box-part {
    font-size:15px;
    padding-top:65px;
    padding-bottom:65px;
    .tit-1 {
        color:#C66300;
        font-size: 30px;
        letter-spacing: 1.5px;
        font-weight: bold;
        margin:10px 0px 15px;
        border-bottom:2px dotted #FACF2B;
        padding-bottom:5px;
        display:inline-block;
    }
    p {
        color:#555555;
        font-size:16px;
        font-style:normal;
        line-height:26px;
    }
    .space-separator {
        height:40px;
    }
    .group2 {
        width:49%;
        display:inline-block;
    }
}
.intro-img {
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    margin-top: 60px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.contenido-1 {
    background:#eee;
    .box-1 {
        margin-left:35px;
        margin-right:35px;
        margin-bottom:40px;
        background:#fff;
    }
    .content-box {
        width:100%;
        padding-left:35px;
        padding-right:35px;
        margin-bottom:40px;
        .box-part {
            background:#fff;
            padding:35px 70px;
        }
    }
}
.contenido {
    padding:20px;
    color:#555;
    .tit,.tit2 {
        color:#068631;
        font-size:25px;
        font-weight:bold;
    }
    .tit2 {
        font-size:20px;
    }
    .img-responsive {
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: -0px 0px 2px 0px rgba(0,0,0,0.55);
        box-shadow: -0px 0px 2px 0px rgba(0,0,0,0.55);
        padding: 10px;
        border: 1px solid #aaaa;
        border-radius: 3px;
    }
}
// Galería 
.imagen_min {
    border:4px solid #068C31;
    margin: 20px 10px;
    display:inline-block;
    border-radius:4px;
}
.imagen_min figcaption  {
  display:none;
}
@media (max-width: @screen-xs-max) {
    .text-container {
        max-width:300px;
        margin:0 auto;
    }
    #nuestra-empresa {
        h2 {
            font-size:16px;
        }
        p {
            font-size:13px;
            line-height:20px;
        }
    }
}

.tabla-habitaciones {
    padding-top:15px;
    padding-bottom:15px;
    background: #FDF9EC;
    border-radius:15px;
    td {
        text-align:center;
        
        
    }
    .first td {
        border-top:0px;
    }
    
}

.btn-ver {
            background:#3C4F6D;
            color:#fff;
            margin-top:10px;
        }
/* Contacto */
#formulario_contacto {

    margin:0 auto;
    .icon-c {
        background: #3C4F6D;
        padding: 10px;
        color: #fff;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        text-align: center;
    }
    .text-lg {
        font-size:18px;
        padding:10px;
    }
    .table {
       
       .left {
          b {
               color:#3C4F6D;
           }
        }
        .right {
            b  {
                color:#068B31
            }
        }
    }
    .bg-h-1 {
       background:#068B31;
    }
    .tit-1 {
        margin-top:50px;
        margin-bottom:50px;
        text-align:Center;
        h2 {
            color:#C66300;
            font-size:30px;
            margin:0px;
            margin-bottom:20px;
        }
        .line {
            height:1px;
            margin:0 auto;
            width:80px;
        }
    }
    form {
        margin-bottom:10px;
        .form-control {
            border-color:#777;
            //border-radius:0px;
        }
        label {
            color:#383839;
            font-size:14px;
            font-weight:normal;
            letter-spacing:1.5px;
            span {
                color:#0082b3;
            }
        }
        input.form-control {
            
        }
        .form-group {
            margin-bottom:20px;
        }
        .btn-custom {
            //border-radius:0px;
            border:0px;
            color:#fff;
            font-weight:bold;
        }
    }
    @media (max-width: @screen-xs-max) {
        .tit-1 { 
            margin-top:30px;
            margin-bottom:30px;
            h2 {
                font-size:16px;
            }
        }
        form {
            label {
                letter-spacing:1.3px;
                font-size:14px;
            }
            input.form-control {
                height:30px;
            }
            .form-group {
                margin-bottom:20px;
            }
        }
    }
}
/* Novedades */
.menu-novedades {
    text-align:center;
    padding:20px;
    .nav {
        display:inline-block;
    }
    .nav-pills {
        li a {
            color:#777;
            font-weight:bold;
        }
        li.active a {
            background:none;
            color:#000
        }
    }
}
.nov-item {
    margin-bottom:10px;
    padding-bottom:5px;
    position:relative;
    .nov-img {
        position:relative;
        .nov-volanta, .nov-fecha {
            background-color: rgba(218, 220, 222, 0.5); 
            color:#fff;
            display:inline-block;
            padding:2px 5px;
            position:absolute;
        }
        .nov-fecha {
            bottom:0;
            right:0
        }
    }
    .nov-data {
        text-align:center;
        h2 {
            color:#4D4D4D;
            font-size:24px;
            font-style: italic;
            letter-spacing:-1px;
            margin:5px 0px 0px;
        }
        p {
            color:#999A9C;
            font-size:16px;
        }
    }
    .nov-link {
        cursor:pointer;
        display:block;
        height:100%;
        position:absolute;
        width:100%;
        z-index:9999;
    }
    &:hover {
        background:#ccc;
        opacity:0.9
    }
}
/* Presupuesto  */
#presupuesto {
    background:#fff;
    padding:20px;
    .form-control {
        max-width:350px;
    }
}
/* Footer */
.footer {
    background:#111;
    margin-top:0px;
    padding:40px 0;
    .logo-foot {
        max-width:100%;
        height:auto;
        margin-top:40px;
    }
    .menu-social {
        display: inline-block;
        border-top:1px solid #8E9194;
        float:none;
        font-size:15px;
        margin-top:18px;
        li a {
            color:#8E288A;
            padding-top:18px;
            padding-bottom:18px;
        }
        @media (max-width: @screen-xs-max) {
            li {
                display:inline-block;
            }
        }
    }
    .contact-menu {
        border-left:1px solid #333;
        padding:30px 0px 30px 20px;
        ul {
            list-style-position: outside;
            margin: 0;
            margin-left: 20px;
            padding: 0;
            li {
                //list-style-image:url(../img/upa_li.png);
                color:#eee;
                line-height:32px;
                padding-left:5px;
                a {
                    color:#eee;
                }
                &.no-i {
                    list-style-image:none;
                    list-style:none;
                }
            }
        }
        &.wpoint {
            ul {
                list-style-type: none;
                margin-left:10px;
                li .fa {
                    color:#F2A118;
                    width:23px;
                    text-align:center;
                }
            }
        }
        .tit {
            margin-top: 0px;
            margin-left: 10px;
            color: #bbb;
            font-size: 20px;
            border-bottom: 1px solid #F2A118;
            display: inline-block;
            padding-bottom: 8px;
        }
    }
   
}
 .copyright {
    background:#000;
    color:#777;
    text-align:center;
    .box-cr {
         padding:10px;
    }
}
/* Botones */
.btn-act-1 {
    background: rgb(232, 92, 2)  !important;
    color: #fff;
    border-radius: 5px;
    &:hover, &:active, &:visited {
        background: rgb(200, 70, 2) !important;
    }
}

/* Cotizar */
.btn-content {
    position: absolute;
    bottom: 310px;
    width:100%;
    text-align:center;
}
a#btn-pedidos {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 30px;
    font-size: 25px;
    border: 3px solid #fff;
    border-radius: 15px;
    line-height:30px;
    display:inline-block;
    text-decoration:none;
    margin:0 auto;
    -webkit-box-shadow: 0px 2px 20px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 20px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 20px -6px rgba(0,0,0,0.75);
    color:#fff;
    font-weight:bold;
    text-shadow: 0px 1px 1px rgba(150, 150, 150, 1);
    &:hover {
        background:#8E288A
    }
    span {
        letter-spacing: 3px;
    }
}
@media (max-width: @screen-md-max) {
    .btn-content {
        bottom: 200px;
    }
}
@media (max-width: @screen-xs-max) {
    .btn-content {
        bottom: 20px;
    }
    a#btn-pedidos {
        padding: 10px 20px;
        color:#fff;
        font-size: 18px;
        line-height:20px;
        border: 2px solid #fff;;
    }
    #header-menu .navbar-brand img {
        max-width: 280px;
    }
}
@media (max-width: @screen-sm-max) {
    .container {
        width:100%
    }
    #intro-1 .left p {
        font-size:15px;
    } 
    
    #intro-1 .left ul {
        font-size:14px;
    }
}
/*---------------------------*/
/*      MEDIA MOVIL         */
/*------------------------*/
@media (max-width: @screen-xs-max) { 
    /* Menu */
    #header-menu {
        .menu-principal {
            display:block;
            float:none;
            li a {
                border:none;
                margin-top:5px;
                padding-left:10px;
                padding-right:10px;
            }
        }
        // menu social celulares
        .menu-social {
            border:none;
            border-top:1px solid #777;
            li {
                display:inline-block;
                a {
                    padding-top:15px;
                    padding-bottom:15px;
                    padding-right:15px;
                    padding-left:15px;
                }
            }
        }
    }
}    